/**
 * Removes leading “Women’s” and “Men’s” text
 */
export function degenderProductGroupTitle(title: string): string {
  return title.replace(/^(?:wo)?men(?:'|’)s\s/i, '')
}

/**
 * Returns the collection handle that should be used for an exclusive product for the given gender category.
 */
export function exclusiveCollectionHandle(genderCategory: string): string {
  // unisex products might belong to both collections, but we need to choose one as the canonical collection
  // for now, choose the women's collection as customers are more likely to browse women's than men's collections
  // this should only matter for customers browsing from search (either internal or external)
  return genderCategory === 'men' ? 'atgt-mens' : 'atgt-womens'
}

type GenericAsset = {
  color: string | null | undefined
  kind: string | null | undefined
}

/** Gallery images in our data are defined as those images where the "kind" matches the "color" */
export function getProductGalleryImages<T extends GenericAsset>(images: T[]): T[] {
  return images.filter(
    image => image.color && image.kind && image.color.toLowerCase() === image.kind.toLowerCase()
  )
}
