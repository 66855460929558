import { useCallback } from 'react'

import { Cart, CartItem } from '../../../types/figs'

import { ECommerceImpression, GtmData, GtmEvent, GtmVariant } from './types'

function mapGtmVariantToEcommerceImpression(
  variant: GtmVariant,
  position?: number
): ECommerceImpression {
  const id = variant?.shopifyId?.match(/\d+/) ?? [] // grab only the numeric portion of the variant id
  const price = variant?.discountPrice || variant?.price
  const productID = variant?.product?.shopifyId
  const productIDList = variant?.externalParentId?.match(/\d+/) ?? []

  const impression: ECommerceImpression = {
    brand: 'FIGS',
    category: variant?.category,
    id: variant?.sku,
    name: variant?.handle,
    price: price && price / 100.0,
    productId: productIDList[0] ?? productID ?? '',
    variant: id[0] ?? '',
  }
  if (position !== undefined) impression.position = position
  return impression
}

function mapCartItemToEcommerceImpression(item: CartItem, position?: number): ECommerceImpression {
  const id = item.variantId?.match(/\d+/) ?? [] // grab only the numeric portion of the variant id
  const impression: ECommerceImpression = {
    brand: 'FIGS',
    category: item.productType,
    id: item.sku,
    name: item.productGroupHandle,
    price: (item.effectivePrice || item.fullPrice) / 100.0,
    productId: item.productId,
    variant: id[0] ?? '',
  }
  if (position !== undefined) impression.position = position
  return impression
}

export function useGoogleTagManager() {
  const getDataLayer = useCallback(() => {
    globalThis.dataLayer = globalThis.dataLayer ?? []
    return globalThis.dataLayer
  }, [])

  const pushGtmData = useCallback(
    (data: GtmData): void => {
      getDataLayer().push(data)
    },
    [getDataLayer]
  )

  const pushGtmEvent = useCallback(
    (event: GtmEvent): void => {
      getDataLayer().push(event)
    },
    [getDataLayer]
  )

  /**
   * Google Enhanced Ecommerce Product Details Impression
   *
   * @argument variant - a variants to record an impression for
   * @argument list (optional) - the title of any collection they are displayed in
   *
   * See: https://developers.google.com/tag-manager/enhanced-ecommerce#details
   */
  const pushProductDetailsImpression = useCallback(
    ({ list, variant }: { list?: string; variant: GtmVariant }) => {
      const impression = mapGtmVariantToEcommerceImpression(variant)

      pushGtmData({
        event: 'view_item',
        ecommerce: {
          currencyCode: variant.currencyType,
          detail: {
            actionField: list ? { list } : {},
            products: [impression],
          },
        },
      })
    },
    [pushGtmData]
  )

  /**
   * Google Enhanced Ecommerce Impression
   *
   * @argument variants - a list of variants to record an impression for
   * @argument list (optional) - the title of any collection they are displayed in
   *
   * See: https://developers.google.com/tag-manager/enhanced-ecommerce#macro
   */
  const pushProductImpressions = useCallback(
    ({ list, variants }: { list?: string; variants: ReadonlyArray<GtmVariant> }) => {
      const impressions = variants.map((variant, position) => {
        const impression = mapGtmVariantToEcommerceImpression(variant, position)
        return list ? { ...impression, list } : impression
      })

      if (!impressions.length) return

      pushGtmData({
        event: 'view_item_list',
        ecommerce: {
          currencyCode: variants[0]!.currencyType,
          impressions,
        },
      })
    },
    [pushGtmData]
  )

  const pushOpenMiniCart = useCallback(
    (cart: Cart | null, currencyCode: string) => {
      if (!cart) return
      const products = []
      for (const cartItemKey in cart.items) {
        products.push(mapCartItemToEcommerceImpression(cart.items[cartItemKey]!))
      }
      pushGtmEvent({
        event: 'open_mini_cart',
        ecommerce: {
          actionField: { list: 'Mini Cart' },
          currencyCode: currencyCode,
          cart: { products },
        },
      })
    },
    [pushGtmEvent]
  )

  const pushSearchSubmitted = useCallback(
    (terms: string) => {
      pushGtmEvent({
        event: 'search_submitted',
        ecommerce: { search: { terms } },
      })
    },
    [pushGtmEvent]
  )

  /**
   * Google Enhanced Ecommerce Add to Cart Event
   *
   * @argument variant: variant to track
   * @argument quantity (optional; default = 1): the quantity of items added to the cart.
   *
   * See: https://developers.google.com/tag-manager/enhanced-ecommerce#add
   */
  const pushAddToCart = useCallback(
    ({ variant, quantity = 1 }: { variant: GtmVariant; quantity?: number }) => {
      if (!quantity) return
      const impression = mapGtmVariantToEcommerceImpression(variant)
      pushGtmEvent({
        event: 'add_to_cart',
        ecommerce: {
          currencyCode: variant.currencyType,
          add: {
            products: [{ ...impression, quantity }],
          },
        },
      })
    },
    [pushGtmEvent]
  )

  /**
   * Google Enhanced Ecommerce Remove from Cart Event
   *
   * @argument variant: variant to track
   * @argument quantity (optional; default = 1): the quantity of items removed from the cart.
   *
   * See: https://developers.google.com/tag-manager/enhanced-ecommerce#remove
   */
  const pushRemoveFromCart = useCallback(
    ({ variant, quantity = 1 }: { variant: GtmVariant; quantity?: number }) => {
      if (!quantity) return
      const impression = mapGtmVariantToEcommerceImpression(variant)
      pushGtmEvent({
        event: 'remove_from_cart',
        ecommerce: {
          currencyCode: variant.currencyType,
          remove: {
            products: [{ ...impression, quantity }],
          },
        },
      })
    },
    [pushGtmEvent]
  )

  /**
   * Google Enhanced Ecommerce Product Click
   *
   * @argument variant: variant to track
   * @argument list: the collection or page section where the variant was placed. e.g., 'womens-core' or 'mini-cart'
   * @argument position (default: 0): the variant's position in the list
   */
  const pushProductClick = useCallback(
    ({
      variant,
      list,
      position = 0,
    }: {
      variant: GtmVariant
      list?: string
      position?: number
    }) => {
      const impression = mapGtmVariantToEcommerceImpression(variant, position)
      pushGtmEvent({
        event: 'select_item',
        ecommerce: {
          click: {
            actionField: list ? { list } : {},
            products: [impression],
          },
        },
      })
    },
    [pushGtmEvent]
  )

  const pushAccountCreated = useCallback(
    (email: string) => {
      pushGtmEvent({
        event: 'account_created',
        ecommerce: { user: { email } },
      })
    },
    [pushGtmEvent]
  )

  const pushCustomerData = useCallback(
    ({
      shopifyId,
      email,
      phone,
      firstName,
      lastName,
      ordersCount,
    }: {
      shopifyId?: string
      email: string
      phone?: string
      firstName?: string
      lastName?: string
      ordersCount?: number
    }) => {
      pushGtmEvent({
        event: 'set_customer_data',
        ecommerce: {
          customer: {
            id: shopifyId,
            email: email,
            phone: phone,
            firstName: firstName,
            lastName: lastName,
            ordersCount: ordersCount,
          },
        },
      })
    },
    [pushGtmEvent]
  )

  const pushClearCustomerData = useCallback(() => {
    pushGtmEvent({
      event: 'clear_customer_data',
      ecommerce: {
        customer: null,
      },
    })
  }, [pushGtmEvent])

  const pushGalleryImageChange = useCallback(
    (galleryImageIndex: number, galleryImageCount: number) => {
      pushGtmEvent({
        'event': 'change_gallery_image',
        'gallery.image.index': galleryImageIndex,
        'gallery.image.count': galleryImageCount,
      })
    },
    [pushGtmEvent]
  )

  return {
    getDataLayer,
    pushGtmEvent,
    pushGtmData,
    pushOpenMiniCart,
    pushAddToCart,
    pushRemoveFromCart,
    pushProductClick,
    pushProductDetailsImpression,
    pushProductImpressions,
    pushSearchSubmitted,
    pushAccountCreated,
    pushCustomerData,
    pushClearCustomerData,
    pushGalleryImageChange,
  }
}
