'use client'

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  NormalizedCacheObject,
  useApolloClient,
} from '@apollo/client'
import {
  ApolloNextAppProvider,
  ApolloClient as NextSSRApolloClient,
  InMemoryCache as NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/client-integration-nextjs'
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import { useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { cookieKeys } from '../_config/Cookies.config'
import {
  getGraphqlClientLinks,
  getShopifyGraphqlClientLinks,
  graphqlClientsDefaultOptions,
  magnoliaSupergraphGraphqlCacheConfig,
} from '../_config/GraphqlClient.config'

import { useCookies } from './CookiesProvider.client'
import { useLocalization } from './LocalizationProvider.client'
import { usePreviewDirectives } from './PreviewDirectivesProvider.client'

export const makeFigsGraphqlClient = (link: ApolloLink) => {
  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(magnoliaSupergraphGraphqlCacheConfig),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            // in a SSR environment, if you use multipart features like
            // @defer, you need to decide how to handle these.
            // This strips all interfaces with a `@defer` directive from your queries.
            new SSRMultipartLink({
              stripDefer: true,
            }),
            link,
          ])
        : link,
    defaultOptions: graphqlClientsDefaultOptions,
  })
}

export type UseGraphqlClientsProps =
  | {
      cookies: ReadonlyRequestCookies
    }
  | undefined

const useMakeFigsGraphqlClient = ({
  graphPathname,
  authorized,
}: {
  graphPathname: string
  authorized: boolean
}) => {
  const [cookies, _setCookie, _removeCookie] = useCookies([cookieKeys.authToken.key])
  const authToken: string | undefined = cookies[cookieKeys.authToken.key]
  const localization = useLocalization()
  const previewDirectives = usePreviewDirectives()
  const link = getGraphqlClientLinks({
    includeAuth: authorized,
    authToken,
    graphPathname,
    localization,
    previewDirectives,
  })
  return useCallback(() => {
    return makeFigsGraphqlClient(link)
  }, [link])
}

const useMakeShopifyGraphqlClient = () => {
  const makeShopifyGraphqlClient = useCallback(() => {
    return new NextSSRApolloClient({
      cache: new NextSSRInMemoryCache(),
      link:
        typeof window === 'undefined'
          ? ApolloLink.from([
              // in a SSR environment, if you use multipart features like
              // @defer, you need to decide how to handle these.
              // This strips all interfaces with a `@defer` directive from your queries.
              new SSRMultipartLink({
                stripDefer: true,
              }),
              getShopifyGraphqlClientLinks(),
            ])
          : getShopifyGraphqlClientLinks(),
      defaultOptions: graphqlClientsDefaultOptions,
    })
  }, [])
  return makeShopifyGraphqlClient
}

type GraphqlClientsProviderInitialState =
  | {
      type: 'rsc-support'
      authorizedClient: NextSSRApolloClient<unknown>
      shopifyClient: NextSSRApolloClient<unknown>
    }
  | {
      type: 'traditional-ssr'
      authorizedClient: ApolloClient<NormalizedCacheObject>
      shopifyClient: ApolloClient<NormalizedCacheObject>
    }
  | undefined

const useGraphqlClientsImpl = (initialState: GraphqlClientsProviderInitialState) => {
  return {
    authorizedClient: initialState!.authorizedClient,
    shopifyClient: initialState!.shopifyClient,
  }
}
const GraphqlClientsContainer = createContainer(useGraphqlClientsImpl)
GraphqlClientsContainer.Provider.displayName = 'GraphqlClientsProviderImpl'

type AppDirectoryGraphqlClientsProviderProps = {
  children: React.ReactNode
  type: 'rsc-support'
}

const AppDirectoryGraphqlClientsProvider = ({
  children,
}: AppDirectoryGraphqlClientsProviderProps) => {
  const makeDefaultFigsGraphqlClient = useMakeFigsGraphqlClient({
    graphPathname: process.env.NEXT_PUBLIC_LOCAL_BASE_URL === 'true' ? '' : '/catalog/graphql',
    authorized: false,
  })
  const makeAuthorizedFigsGraphqlClient = useMakeFigsGraphqlClient({
    graphPathname: process.env.NEXT_PUBLIC_LOCAL_BASE_URL === 'true' ? '' : '/shop/graphql',
    authorized: true,
  })
  const makeShopifyGraphqlClient = useMakeShopifyGraphqlClient()

  const authorizedClient = useMemo(() => {
    return makeAuthorizedFigsGraphqlClient()
  }, [makeAuthorizedFigsGraphqlClient])

  const shopifyClient = useMemo(() => {
    return makeShopifyGraphqlClient()
  }, [makeShopifyGraphqlClient])

  return (
    <ApolloNextAppProvider makeClient={makeDefaultFigsGraphqlClient}>
      <GraphqlClientsContainer.Provider
        initialState={{ type: 'rsc-support', authorizedClient, shopifyClient }}
      >
        {children}
      </GraphqlClientsContainer.Provider>
    </ApolloNextAppProvider>
  )
}

type PagesDirectoryGraphqlClientsProviderProps = {
  children: React.ReactNode
  type: 'traditional-ssr'
  defaultClient: ApolloClient<NormalizedCacheObject>
  authorizedClient: ApolloClient<NormalizedCacheObject>
  shopifyClient: ApolloClient<NormalizedCacheObject>
}

const PagesDirectoryGraphqlClientsProvider = ({
  children,
  defaultClient,
  authorizedClient,
  shopifyClient,
}: PagesDirectoryGraphqlClientsProviderProps) => {
  return (
    <ApolloProvider client={defaultClient}>
      <GraphqlClientsContainer.Provider
        initialState={{
          type: 'traditional-ssr',
          authorizedClient: authorizedClient,
          shopifyClient: shopifyClient,
        }}
      >
        {children}
      </GraphqlClientsContainer.Provider>
    </ApolloProvider>
  )
}

type GraphqlClientsProviderProps =
  | AppDirectoryGraphqlClientsProviderProps
  | PagesDirectoryGraphqlClientsProviderProps

export const GraphqlClientsProvider = (props: GraphqlClientsProviderProps) => {
  if (props.type === 'rsc-support') {
    return <AppDirectoryGraphqlClientsProvider {...props} />
  } else {
    return <PagesDirectoryGraphqlClientsProvider {...props} />
  }
}

export const useGraphqlClients = (): {
  cacheOptimizedClient: ApolloClient<object>
} & ReturnType<typeof GraphqlClientsContainer.useContainer> => {
  const mainClient = useApolloClient()
  const additionalClients = GraphqlClientsContainer.useContainer()
  return {
    cacheOptimizedClient: mainClient,
    ...additionalClients,
  }
}
