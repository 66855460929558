'use client'

import {
  ComponentProps,
  ElementType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { IconButton } from '@syconium/little-miss-figgy/dist/components/IconButton/IconButton'
import { RotateClockwise } from '@syconium/little-miss-figgy/dist/components/Icons/BaseIcon'
import { Carat } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/Carat'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly/index'
import { fromLg, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { fromMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { useSupportsHover } from '@syconium/little-miss-figgy/dist/lib/hooks/useSupportsHover'

export type TilesLayoutContextValue = {
  visibleTiles: {
    sm: number
    md: number
    lg?: number
  }
  layout: {
    sm: 'grid' | 'slider'
    md: 'grid' | 'slider'
    lg?: 'grid' | 'slider'
  }
  gutters: {
    sm: number
    md: number
    lg?: number
  }
}

const TilesLayoutContext = createContext<TilesLayoutContextValue | undefined>(undefined)

export const useTilesLayoutContext = () => {
  return useContext<TilesLayoutContextValue | undefined>(TilesLayoutContext)
}

const StyledTilesLayoutWrapper = styled.div<{
  $layout: TilesLayoutContextValue['layout']
  $justifyContent?: ComponentProps<typeof StyledTilesLayout>['$justifyContent']
}>`
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  z-index: 0;

  ${o => {
    const smTextAlign =
      o.$layout.sm === 'slider' && o.$justifyContent?.sm === 'center' ? 'center' : 'left'
    const mdTextAlign =
      o.$layout.md === 'slider' && o.$justifyContent?.md === 'center' ? 'center' : 'left'
    const lgTextAlign = !o.$layout.lg
      ? mdTextAlign
      : o.$layout.lg === 'slider' && o.$justifyContent?.lg === 'center'
      ? 'center'
      : 'left'

    return `
      ${untilMd} {
        text-align: ${smTextAlign};
      }

      ${fromMd} {
        text-align: ${mdTextAlign};
      }

      ${fromLg} {
        text-align: ${lgTextAlign};
      }

    `
  }};
`

const gapSpacingUnits = 4

const StyledTilesLayout = styled.div<{
  $layout: TilesLayoutContextValue['layout']
  $visibleTiles: TilesLayoutContextValue['visibleTiles']
  $gutters: TilesLayoutContextValue['gutters']
  $justifyContent?: {
    sm: 'left' | 'center'
    md: 'left' | 'center'
    lg?: 'left' | 'center'
  }
}>`
  max-width: 100%;
  outline: none;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-shrink: 0;
  flex-grow: 1;
  gap: ${o => o.theme.spacing(gapSpacingUnits)};
  z-index: 0;
  box-sizing: border-box;

  ${o => {
    const smJustify =
      o.$justifyContent?.sm === 'center' && o.$layout.sm === 'grid' ? 'center' : 'start'
    const mdJustify =
      o.$justifyContent?.md === 'center' && o.$layout.md === 'grid' ? 'center' : 'start'
    const lgJustify = !o.$justifyContent
      ? mdJustify
      : o.$justifyContent?.lg === 'center' && o.$layout.lg === 'grid'
      ? 'center'
      : 'start'

    const smFlexWrap = o.$layout.sm === 'grid' ? 'wrap' : 'nowrap'
    const mdFlexWrap = o.$layout.md === 'grid' ? 'wrap' : 'nowrap'
    const lgFlexWrap = !o.$layout.lg ? mdFlexWrap : o.$layout.lg === 'grid' ? 'wrap' : 'nowrap'

    const smGutter = o.$layout.sm === 'grid' ? o.$gutters.sm : 0
    const mdGutter = o.$layout.md === 'grid' ? o.$gutters.md : 0
    const lgGutter = !o.$layout.lg ? mdGutter : o.$layout.lg === 'grid' ? o.$gutters.lg : 0

    const smWidth = o.$layout.sm === 'grid' ? '100%' : 'unset'
    const mdWidth = o.$layout.md === 'grid' ? '100%' : 'unset'
    const lgWidth = !o.$layout.lg ? mdWidth : o.$layout.lg === 'grid' ? '100%' : 'unset'

    const smDisplay = o.$layout.sm === 'grid' ? 'flex' : 'inline-flex'
    const mdDisplay = o.$layout.md === 'grid' ? 'flex' : 'inline-flex'
    const lgDisplay = !o.$layout.lg ? mdDisplay : o.$layout.lg === 'grid' ? 'flex' : 'inline-flex'

    return `
      ${untilMd} {
        display: ${smDisplay};
        width: ${smWidth};
        justify-content: ${smJustify};
        flex-wrap: ${smFlexWrap};
        padding-left: ${smGutter}px;
        padding-right: ${smGutter}px;
      }

      ${fromMd} {
        display: ${mdDisplay};
        width: ${mdWidth};
        justify-content: ${mdJustify};
        flex-wrap: ${mdFlexWrap};
        padding-left: ${mdGutter}px;
        padding-right: ${mdGutter}px;
      }

      ${fromLg} {
        display: ${lgDisplay};
        width: ${lgWidth};
        justify-content: ${lgJustify};
        flex-wrap: ${lgFlexWrap};
        padding-left: ${lgGutter}px;
        padding-right: ${lgGutter}px;
      }
    `
  }};
`

const StyledSliderButtonWithoutContext = styled(IconButton)<{
  $liftedPixels: number
  $direction: 'back' | 'forward'
  $gutters?: TilesLayoutContextValue['gutters']
}>`
  position: absolute;
  z-index: ${o => o.theme.zIndex.sticky - 1};

  ${o => {
    const top = `calc(50% - ${o.$liftedPixels}px)`
    const side = o.$direction === 'back' ? 'left' : 'right'
    const smGutter = o.$gutters?.sm ?? 0
    const mdGutter = o.$gutters?.md ?? 0
    const lgGutter = o.$gutters?.lg ?? o.$gutters?.md ?? 0

    return `
      top: ${top};

      ${untilMd} {
        ${side}: ${smGutter}px;
      }

      ${fromMd} {
        ${side}: ${mdGutter}px;
      }

      ${fromLg} {
        ${side}: ${lgGutter}px;
      }
    `
  }};
`

const StyledSliderButton = ({
  variant = 'black-on-white-no-border',
  $gutters: $guttersProp,
  ...rest
}: ComponentProps<typeof StyledSliderButtonWithoutContext>) => {
  const tilesLayoutContext = useTilesLayoutContext()
  const $gutters = $guttersProp ?? tilesLayoutContext?.gutters
  return <StyledSliderButtonWithoutContext variant={variant} $gutters={$gutters} {...rest} />
}

const StyledGutterPlaceholder = styled.div<{
  $layout: TilesLayoutContextValue['layout']
  $gutters: TilesLayoutContextValue['gutters']
}>`
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;

  &:first-child {
    margin-right: -${o => o.theme.spacing(gapSpacingUnits)};
  }

  &:last-child {
    margin-left: -${o => o.theme.spacing(gapSpacingUnits)};
  }

  ${o => {
    const smDisplay = o.$layout.sm === 'grid' ? 'none' : 'unset'
    const mdDisplay = o.$layout.md === 'grid' ? 'none' : 'unset'
    const lgDisplay = !o.$layout.lg ? mdDisplay : o.$layout.lg === 'grid' ? 'none' : 'unset'

    return `
      ${untilMd} {
        text-align: left;
        display: ${smDisplay};
        width: ${o.$gutters.sm}px;
        flex-basis: ${o.$gutters.sm}px;
      }

      ${fromMd} {
        text-align: left;
        display: ${mdDisplay};
        width: ${o.$gutters.md}px;
        flex-basis: ${o.$gutters.md}px;
      }
      ${fromLg}{
        text-align: left;
        display: ${lgDisplay};
        width: ${o.$gutters.lg ?? o.$gutters.md}px;
        flex-basis: ${o.$gutters.lg ?? o.$gutters.md}px;
      }
    `
  }};
`

type TilesSliderProps = {
  className?: string
  children?: React.ReactNode
  scrollBackLabel: string
  scrollForwardLabel: string
  liftButtonsPixels?: number
  layout: TilesLayoutContextValue['layout']
  visibleTiles: TilesLayoutContextValue['visibleTiles']
  gutters: TilesLayoutContextValue['gutters']
  justifyContent?: ComponentProps<typeof StyledTilesLayout>['$justifyContent']
}

export const LIFTED_BUTTONS_DEFAULT_PIXELS = 24
export const TilesLayout = ({
  className,
  children,
  scrollBackLabel,
  scrollForwardLabel,
  liftButtonsPixels,
  layout: layoutProp,
  visibleTiles: visibleTilesProp,
  gutters: guttersProp,
  justifyContent,
}: TilesSliderProps) => {
  const [scrollsLeft, setScrollsLeft] = useState(false)
  const [scrollsRight, setScrollsRight] = useState(false)
  const hoverSupported = useSupportsHover()
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const context = useMemo(() => {
    return {
      layout: layoutProp,
      visibleTiles: visibleTilesProp,
      gutters: guttersProp,
    }
  }, [layoutProp, guttersProp, visibleTilesProp])

  const scrollRight = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += sliderRef.current.getBoundingClientRect().width
    }
  }, [])

  const scrollLeft = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= sliderRef.current.getBoundingClientRect().width
    }
  }, [])

  const checkIfScrollable = useCallback(() => {
    if (sliderRef.current && sliderRef.current.scrollWidth > sliderRef.current.clientWidth) {
      setScrollsLeft(sliderRef.current.scrollLeft > 8)
      setScrollsRight(
        sliderRef.current.scrollLeft + sliderRef.current.clientWidth + 8 <
          sliderRef.current.scrollWidth
      )
    } else {
      setScrollsLeft(false)
      setScrollsRight(false)
    }
  }, [])

  useEffect(() => {
    checkIfScrollable()

    const observer = new MutationObserver((mutationList, _observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          checkIfScrollable()
        }
      }
    })

    const targetNode = sliderRef.current
    window?.addEventListener('resize', checkIfScrollable, { passive: true })
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true })
    }

    return () => {
      window?.removeEventListener('resize', checkIfScrollable)
      observer.disconnect()
    }
  }, [checkIfScrollable])

  return (
    <TilesLayoutContext.Provider value={context}>
      <StyledTilesLayoutWrapper $layout={context.layout} $justifyContent={justifyContent}>
        {hoverSupported && scrollsLeft ? (
          <StyledSliderButton
            onClick={scrollLeft}
            $liftedPixels={liftButtonsPixels ?? 0}
            $direction='back'
          >
            <ScreenReaderOnly>{scrollBackLabel}</ScreenReaderOnly>
            <Carat
              aria-hidden={true}
              height='18'
              rotateClockwise={RotateClockwise.Half}
              width='16'
            />
          </StyledSliderButton>
        ) : null}
        <StyledTilesLayout
          className={className}
          ref={sliderRef}
          onScroll={checkIfScrollable}
          $layout={context.layout}
          $visibleTiles={context.visibleTiles}
          $gutters={context.gutters}
          $justifyContent={justifyContent}
        >
          <StyledGutterPlaceholder $layout={context.layout} $gutters={context.gutters} />
          {children}
          <StyledGutterPlaceholder $layout={context.layout} $gutters={context.gutters} />
        </StyledTilesLayout>
        {hoverSupported && scrollsRight ? (
          <StyledSliderButton
            onClick={scrollRight}
            $liftedPixels={liftButtonsPixels ?? 0}
            $direction='forward'
          >
            <ScreenReaderOnly>{scrollForwardLabel}</ScreenReaderOnly>
            <Carat aria-hidden={true} height='18' width='16' />
          </StyledSliderButton>
        ) : null}
      </StyledTilesLayoutWrapper>
    </TilesLayoutContext.Provider>
  )
}

type StyledTileProps = {
  $layout?: TilesLayoutContextValue['layout']
  $visibleTiles?: TilesLayoutContextValue['visibleTiles']
  $gutters?: TilesLayoutContextValue['gutters']
}

const portionOfAdditionalTileOverlappingViewportSm = 32
const portionOfAdditionalTileOverlappingViewportMd = 172

const StyledTile = styled.div<StyledTileProps>`
  flex-grow: 0;
  flex-shrink: 0;

  ${o => {
    if (!o.$visibleTiles) return ''

    const smSliderTileWidth = `calc(
      ${100 / o.$visibleTiles.sm}vw - ${o.theme.spacing(gapSpacingUnits)} - ${
        portionOfAdditionalTileOverlappingViewportSm / o.$visibleTiles.sm
      }px - ${o.$gutters?.sm && o.$gutters.sm > 0 ? o.$gutters.sm / o.$visibleTiles.sm : 0}px
    );`

    const smGridTileWidth = `calc(
      ${100 / o.$visibleTiles.sm}% - calc(${o.theme.spacing(gapSpacingUnits)} * ${
        o.$visibleTiles.sm - 1
      } / ${o.$visibleTiles.sm})
    );`

    const mdSliderTileWidth = `calc(
      ${100 / o.$visibleTiles.md}vw - ${o.theme.spacing(gapSpacingUnits)} - ${
        portionOfAdditionalTileOverlappingViewportMd / o.$visibleTiles.md
      }px - ${o.$gutters?.md && o.$gutters.md > 0 ? o.$gutters.md / o.$visibleTiles.md : 0}px
    );`

    const mdGridTileWidth = `calc(
      ${100 / o.$visibleTiles.md}% - calc(${o.theme.spacing(gapSpacingUnits)} * ${
        o.$visibleTiles.md - 1
      } / ${o.$visibleTiles.md})
    );`

    const lgSliderTileWidth = `calc(
      ${100 / (o.$visibleTiles?.lg ?? o.$visibleTiles.md)}vw - ${o.theme.spacing(
        gapSpacingUnits
      )} - ${
        portionOfAdditionalTileOverlappingViewportMd / (o.$visibleTiles?.lg ?? o.$visibleTiles.md)
      }px - ${
        o.$gutters?.lg && o.$gutters.lg > 0
          ? o.$gutters.lg / (o.$visibleTiles?.lg ?? o.$visibleTiles.md)
          : o.$gutters?.md && o.$gutters.md > 0
          ? o.$gutters.md / (o.$visibleTiles?.md ?? o.$visibleTiles.md)
          : 0
      }px
    );`

    const lgGridTileWidth = `calc(
      ${100 / (o.$visibleTiles?.lg ?? o.$visibleTiles.md)}% - calc(${o.theme.spacing(
        gapSpacingUnits
      )} * ${(o.$visibleTiles?.lg ?? o.$visibleTiles.md) - 1} / ${
        o.$visibleTiles?.lg ?? o.$visibleTiles.md
      })
    );`

    return `
      ${untilMd} {
        width: ${o.$layout?.sm === 'grid' ? smGridTileWidth : smSliderTileWidth};
        flex-basis: ${o.$layout?.sm === 'grid' ? smGridTileWidth : smSliderTileWidth};
      }

      ${fromMd} {
        width: ${o.$layout?.md === 'grid' ? mdGridTileWidth : mdSliderTileWidth};
        flex-basis: ${o.$layout?.md === 'grid' ? mdGridTileWidth : mdSliderTileWidth};
      }
      ${fromLg} {
        width: ${(o.$layout?.lg ?? o.$layout?.md) === 'grid' ? lgGridTileWidth : lgSliderTileWidth};
        flex-basis: ${
          (o.$layout?.lg ?? o.$layout?.md) === 'grid' ? lgGridTileWidth : lgSliderTileWidth
        };
      }
      
    `
  }};
`

type TileProps = {
  visibleTiles?: TilesLayoutContextValue['visibleTiles']

  as?: ElementType
} & Omit<ComponentProps<typeof StyledTile>, keyof StyledTileProps>

export const Tile = ({ visibleTiles, ...props }: TileProps) => {
  const tilesLayoutContext = useTilesLayoutContext()

  return (
    <StyledTile
      $visibleTiles={visibleTiles ?? tilesLayoutContext?.visibleTiles}
      $gutters={tilesLayoutContext?.gutters}
      $layout={tilesLayoutContext?.layout}
      {...props}
    />
  )
}
