'use client'

import NextLink, { type LinkProps as NextLinkProps } from 'next/link'
import { type AnchorHTMLAttributes, type ReactNode, forwardRef } from 'react'
import styled from 'styled-components'

import { focusOutline } from '@syconium/little-miss-figgy'

type LinkProps = NextLinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> & {
    children?: ReactNode
  }

const StyledNextLink = styled(NextLink)`
  ${focusOutline};
`

const LinkNoPrefetch = forwardRef<HTMLAnchorElement, LinkProps>(function LinkWithRef(
  {
    // Turn next/link prefetching off by default.
    // @see https://github.com/vercel/next.js/discussions/24009
    prefetch = false,
    href: hrefProp,
    ...rest
  },
  ref
) {
  const href = typeof hrefProp === 'string' ? hrefProp.trim() : hrefProp
  return <StyledNextLink prefetch={prefetch} {...rest} href={href} ref={ref} />
})

export { LinkNoPrefetch as NextLink }
