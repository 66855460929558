import { OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { QueryStatus } from '../../lib/hooks/types'
import { GraphQlClients } from '../_config/GraphqlClient.config'
import { useGraphqlClients } from '../_providers/GraphqlClientsProvider.client'

const graphqlClientsMap: Record<GraphQlClients, keyof ReturnType<typeof useGraphqlClients>> = {
  'cache-optimized': 'cacheOptimizedClient',
  'auth-supported': 'authorizedClient',
  'shopify': 'shopifyClient',
}

export const useNextQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  queryOptions?: Omit<QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'client'> & {
    client?: GraphQlClients
  }
) => {
  const additionalClients = useGraphqlClients()
  const queryResult = useQuery(query, {
    ...queryOptions,
    client: queryOptions?.client
      ? additionalClients[graphqlClientsMap[queryOptions.client]]
      : undefined,
  })
  const status = useMemo<QueryStatus>(() => {
    if (!queryResult.called) return 'idle'
    if (queryResult.loading) return 'pending'
    if (queryResult.error) return 'rejected'
    return 'resolved'
  }, [queryResult.called, queryResult.error, queryResult.loading])
  return {
    ...queryResult,
    status,
  }
}
