import { localStorageNamespace } from '@syconium/iron-figs'
import { isServer } from '@syconium/weeping-figs'

import { reportClientError } from '../../app/_components/chrome/scripts/DataDogRumScript'

export const getLSKey = (key: string) => `${localStorageNamespace}${key}`

export const setItemInLS = (key: string, value: string) => {
  if (isServer) return
  const lsKey = getLSKey(key)
  try {
    localStorage?.setItem(lsKey, value)
  } catch (error) {
    reportClientError({
      error,
      context: {
        scope: 'localStorage',
        label: 'setItemInLS',
        lsKey,
      },
    })
  }
}

export const getItemFromLS = (key: string) => {
  if (isServer) return
  const lsKey = getLSKey(key)
  return localStorage?.getItem(lsKey)
}

export const removeItemFromLS = (key: string) => {
  if (isServer) return
  localStorage?.removeItem(getLSKey(key))
}
