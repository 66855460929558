import { ShopRegion, SupportedLocaleIdentifier, supportedRegions } from '@syconium/weeping-figs'

// Be careful. This map would persist serverside and be shared across all SSRs.
// But creating a regex or a number formatter is expensive. So lets avoid doing
// it everytime a function is called by leveraging these shared const variables.
const currencyFormattersByLocaleByCurrency: Record<string, Record<string, Intl.NumberFormat>> = {}
const maxFractionDigitsByLocaleByCurrency: Record<string, Record<string, number>> = {}
const trailingUsdZerosRegex = /\.00$/

interface IFormatPrettyCurrencyOptions {
  truncateTrailingZeros: boolean
  explicitFormat: boolean
}

const defaultFormatPrettyCurrencyOptions: IFormatPrettyCurrencyOptions = {
  truncateTrailingZeros: false,
  explicitFormat: false,
}

export function formatPrettyCurrency(
  cents: number,
  currency: string | undefined = 'USD',
  locale: string | undefined = 'en-US',
  {
    truncateTrailingZeros = defaultFormatPrettyCurrencyOptions.truncateTrailingZeros,
    explicitFormat = defaultFormatPrettyCurrencyOptions.explicitFormat,
  }: Partial<IFormatPrettyCurrencyOptions> = defaultFormatPrettyCurrencyOptions
): string {
  if (currency === 'USD') {
    locale = 'en-US'
    explicitFormat = false
  } else if (currency === 'CHF') {
    explicitFormat = false
  }

  // Check if we already have the maximumFractionDigits cached
  let maximumFractionDigits = maxFractionDigitsByLocaleByCurrency[locale]?.[currency]
  if (!maximumFractionDigits) {
    // Only create temp formatter if we don't have the value cached
    const tempFormatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    })
    maximumFractionDigits = tempFormatter.resolvedOptions().maximumFractionDigits ?? 2
    maxFractionDigitsByLocaleByCurrency[locale] = maxFractionDigitsByLocaleByCurrency[locale] ?? {}
    maxFractionDigitsByLocaleByCurrency[locale]![currency] = maximumFractionDigits
  }

  const divisor = Math.pow(10, maximumFractionDigits ?? 2)

  // These are expensive to create in memory for every price value on the screen.
  // Reuse them if we have already made them.
  const numberFormatter =
    currencyFormattersByLocaleByCurrency[locale]?.[currency] ??
    new Intl.NumberFormat(locale, {
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: maximumFractionDigits ?? 2,
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
    })

  // We don't want to divide by 100 if its a currency like JPY
  // Get the natural fraction digits for this currency

  const prettyCurrency: string = numberFormatter.format(cents / divisor)

  let formattedCurrency = prettyCurrency

  // Add it if it was missing.
  if (currencyFormattersByLocaleByCurrency[locale]?.[currency] === undefined) {
    currencyFormattersByLocaleByCurrency[locale] =
      currencyFormattersByLocaleByCurrency[locale] ?? {}
    currencyFormattersByLocaleByCurrency[locale]![currency] = numberFormatter
  }

  if (truncateTrailingZeros) formattedCurrency = prettyCurrency.replace(trailingUsdZerosRegex, '')
  if (explicitFormat) formattedCurrency = `${formattedCurrency} ${currency}`

  return formattedCurrency
}

type IntlPrettyCurrencyProps = {
  cents: number | undefined
  region: ShopRegion
  locale: SupportedLocaleIdentifier
  truncateTrailingZeros?: boolean
  explicitFormat?: boolean
}

export const intlPrettyCurrency = ({
  cents = 0,
  region,
  locale,
  truncateTrailingZeros = false,
  explicitFormat = false,
}: IntlPrettyCurrencyProps): string => {
  const computedCurrency = region?.currency ?? supportedRegions.US.currency
  const computedLocale = locale ?? supportedRegions.US.defaultLocale

  return formatPrettyCurrency(cents, computedCurrency, computedLocale, {
    truncateTrailingZeros,
    explicitFormat,
  })
}
