'use client'

import React from 'react'

import { intlPrettyCurrency } from '../../../lib/utils/currency'
import { useLocalization } from '../../_providers/LocalizationProvider.client'

import { CompareAtPrice } from './CompareAtPrice.client'
import { MarkedDownPrice } from './MarkedDownPrice.client'

type ProductGroupPricingInformation = {
  priceRange: { min: number; max: number }
  discountPriceRange?: {
    min: number
    max: number
    minPercentageOff: number
    maxPercentageOff: number
  }
}

type ProductGroupWithPricedProducts = {
  products: Array<{
    priceRange:
      | null
      | undefined
      | {
          min: number
          max: number
        }
    defaultVariant: {
      price: number
      discountPrice: null | undefined | number
    }
  }>
}

export function derivePricingForProductGroup(
  productGroupWithPricedProducts: ProductGroupWithPricedProducts
): ProductGroupPricingInformation | undefined {
  let pricingInformation: ProductGroupPricingInformation | undefined = undefined

  for (const {
    defaultVariant: { price, discountPrice },
    priceRange,
  } of productGroupWithPricedProducts.products) {
    const minPrice = Math.min(
      pricingInformation?.priceRange.min ?? price,
      priceRange?.min ?? price,
      price
    )
    const maxPrice = Math.max(
      pricingInformation?.priceRange.max ?? price,
      priceRange?.max ?? price,
      price
    )

    const percentageOff: number | undefined = discountPrice
      ? Math.round(((price - discountPrice) / price) * 100)
      : undefined
    const minDiscountPrice = Math.min(
      pricingInformation?.discountPriceRange?.min ?? discountPrice ?? price,
      discountPrice ?? price
    )
    const maxDiscountPrice = Math.max(
      pricingInformation?.discountPriceRange?.max ?? discountPrice ?? price,
      discountPrice ?? price
    )
    const maxPercentageOff = Math.max(
      pricingInformation?.discountPriceRange?.maxPercentageOff ?? 0,
      percentageOff ?? 0
    )
    const minPercentageOff = Math.min(
      percentageOff ?? maxPercentageOff,
      pricingInformation?.discountPriceRange?.minPercentageOff ?? maxPercentageOff
    )

    const discountPriceRange =
      minDiscountPrice < minPrice
        ? {
            min: minDiscountPrice,
            max: maxDiscountPrice,
            maxPercentageOff,
            minPercentageOff,
          }
        : undefined

    pricingInformation = {
      priceRange: {
        min: minPrice,
        max: maxPrice,
      },
      discountPriceRange: discountPriceRange,
    }
  }

  return pricingInformation
}

type PriceRangeForProductGroupProps = ProductGroupPricingInformation & {
  renderPriceText?: (children: React.ReactNode) => React.ReactNode
  renderMarkdownText?: (children: React.ReactNode) => React.ReactNode
  renderCompareAtText?: (children: React.ReactNode) => React.ReactNode
  className?: string
} & (
    | {
        upToPercentageOff: string
        percentageOff: string
      }
    | {
        upToPercentageOff: null
        percentageOff: null
      }
  )

export const PriceRangeForProductGroup = ({
  priceRange,
  discountPriceRange,
  upToPercentageOff,
  percentageOff,
  renderPriceText,
  renderMarkdownText,
  renderCompareAtText,
  className,
}: PriceRangeForProductGroupProps) => {
  const { region, locale } = useLocalization()

  const percentageOffStatement =
    percentageOff && discountPriceRange
      ? discountPriceRange.minPercentageOff !== discountPriceRange.maxPercentageOff
        ? ` (${upToPercentageOff.replace(
            '{{percentage}}',
            discountPriceRange.maxPercentageOff + ''
          )})`
        : ` (${percentageOff.replace('{{percentage}}', discountPriceRange.maxPercentageOff + '')})`
      : ''

  const discountRangeStatement = discountPriceRange
    ? discountPriceRange.min !== discountPriceRange.max
      ? `${intlPrettyCurrency({
          cents: discountPriceRange.min,
          region: region,
          locale: locale,
        })}-${intlPrettyCurrency({
          cents: discountPriceRange.max,
          region: region,
          locale: locale,
        })}${percentageOffStatement}`
      : `${intlPrettyCurrency({
          cents: discountPriceRange.min,
          region: region,
          locale: locale,
        })}${percentageOffStatement}`
    : undefined

  const priceRangeStatement =
    priceRange.min !== priceRange.max
      ? `${intlPrettyCurrency({
          cents: priceRange.min,
          region: region,
          locale: locale,
        })}-${intlPrettyCurrency({
          cents: priceRange.max,
          region: region,
          locale: locale,
        })}`
      : `${intlPrettyCurrency({
          cents: priceRange.min,
          region: region,
          locale: locale,
        })}`

  return (
    <>
      {discountRangeStatement ? (
        <span className={className}>
          {renderMarkdownText ? (
            renderMarkdownText(discountRangeStatement)
          ) : (
            <MarkedDownPrice>{discountRangeStatement}</MarkedDownPrice>
          )}{' '}
          {renderCompareAtText ? (
            renderCompareAtText(priceRangeStatement)
          ) : (
            <CompareAtPrice>{priceRangeStatement}</CompareAtPrice>
          )}
        </span>
      ) : (
        <span className={className}>
          {renderPriceText ? renderPriceText(priceRangeStatement) : priceRangeStatement}
        </span>
      )}
    </>
  )
}
