'use client'

import styled from 'styled-components'

import { fromMd, pageLayoutMaxWidth } from '@syconium/little-miss-figgy'

export const sectionRowGapUntilMd = 40
export const sectionRowGapFromMd = 100

export const sectionSideGutterUntilMd = 16
export const sectionSideGutterFromMd = 40
export const sectionSideGutterFromMdAndExpanded = 120

const StyledPageSection = styled.section<{
  $hasVerticalMargin?: boolean
}>`
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;

  margin-bottom: ${o => (o.$hasVerticalMargin ? `${sectionRowGapUntilMd}` : '0')}px;
  ${fromMd} {
    margin-bottom: ${o => (o.$hasVerticalMargin ? `${sectionRowGapFromMd}` : '0')}px;
  }
`

type PageSectionProps = {
  children?: React.ReactNode
  className?: string
  hasVerticalMargin?: boolean
}

export const PageSection = ({ hasVerticalMargin = true, ...rest }: PageSectionProps) => {
  return <StyledPageSection {...rest} $hasVerticalMargin={hasVerticalMargin} />
}

type GutterSizeType = 'default' | 'expanded' | 'none' | undefined

const getGutterSizeForType = (gutterSizeType: GutterSizeType, viewportSize: 'sm' | 'md') => {
  switch (gutterSizeType) {
    case 'expanded':
      return viewportSize === 'sm' ? sectionSideGutterUntilMd : sectionSideGutterFromMdAndExpanded
    case 'none':
      return 0
    default:
      return viewportSize === 'sm' ? sectionSideGutterUntilMd : sectionSideGutterFromMd
  }
}

type GutterSizeRequest =
  | GutterSizeType
  | {
      sm: GutterSizeType
      md: GutterSizeType
    }

const getGutterSize = (gutterSizeRequest: GutterSizeRequest, viewportSize: 'sm' | 'md') => {
  if (typeof gutterSizeRequest === 'object') {
    return getGutterSizeForType(gutterSizeRequest[viewportSize], viewportSize)
  } else {
    return getGutterSizeForType(gutterSizeRequest, viewportSize)
  }
}

type StyledPageSectionContentProps = {
  $gutterSize?: GutterSizeRequest
}

const StyledPageSectionContent = styled.div<StyledPageSectionContentProps>`
  margin: 0px auto;
  position: relative;
  box-sizing: border-box;
  max-width: ${pageLayoutMaxWidth};

  padding: 0 ${o => getGutterSize(o.$gutterSize, 'sm')}px;
  ${fromMd} {
    padding: 0 ${o => getGutterSize(o.$gutterSize, 'md')}px;
  }
`

type PageSectionContentProps = {
  children?: React.ReactNode
  className?: string
  gutterSize?: StyledPageSectionContentProps['$gutterSize']
}

export const PageSectionContent = ({
  gutterSize = 'default',
  ...rest
}: PageSectionContentProps) => {
  return <StyledPageSectionContent {...rest} $gutterSize={gutterSize} />
}
