import { LegacyFit } from '../../brunswick/constants/LegacyFit'

import type { ProductAvailabilitySlice } from '../../types/graphql'

export function isColorAvailable({
  availableProducts,
  colorKey,
  selectedFitKey,
  selectedSizeShorthand,
}: {
  availableProducts: AvailableProducts
  colorKey: string
  selectedFitKey: string | undefined
  selectedSizeShorthand?: string
}): boolean {
  if (!selectedFitKey) return false
  const sizesInColorAndFit = availableProducts[colorKey]?.[selectedFitKey] ?? []
  return selectedSizeShorthand
    ? sizesInColorAndFit.includes(selectedSizeShorthand)
    : sizesInColorAndFit.length > 0
}

export interface ColorPriceRange {
  priceRange: { min: number; max: number }
  discountPriceRange?: {
    min: number
    max: number
    minPercentageOff: number
    maxPercentageOff: number
  }
}

export interface ColorPriceRanges {
  [colorKey: string]: ColorPriceRange | undefined
}

export function deriveColorPriceRanges(
  productsForAvailability: ProductAvailabilitySlice[]
): ColorPriceRanges {
  const priceRangesByColor: ColorPriceRanges = {}

  for (const {
    colorInfo,
    rawFit,
    defaultVariant,
    priceRange: untypedPriceRange,
  } of productsForAvailability) {
    if (!colorInfo || !colorInfo.rawName) continue
    if (!rawFit) continue

    const existingEntry = priceRangesByColor[colorInfo.rawName]

    const price: number = defaultVariant.price
    const priceRange:
      | {
          min: number
          max: number
        }
      | undefined = untypedPriceRange
    const minPrice = Math.min(
      existingEntry?.priceRange.min ?? price,
      priceRange?.min ?? price,
      price
    )
    const maxPrice = Math.max(
      existingEntry?.priceRange.max ?? price,
      priceRange?.max ?? price,
      price
    )

    const discountPrice: number | undefined = defaultVariant.discountPrice
    const percentageOff: number | undefined = discountPrice
      ? Math.round(((price - discountPrice) / price) * 100)
      : undefined
    const minDiscountPrice = Math.min(
      existingEntry?.discountPriceRange?.min ?? discountPrice ?? price,
      discountPrice ?? price
    )
    const maxDiscountPrice = Math.max(
      existingEntry?.discountPriceRange?.max ?? discountPrice ?? price,
      discountPrice ?? price
    )
    const maxPercentageOff = Math.max(
      existingEntry?.discountPriceRange?.maxPercentageOff ?? 0,
      percentageOff ?? 0
    )
    const minPercentageOff = Math.min(
      percentageOff ?? maxPercentageOff,
      existingEntry?.discountPriceRange?.minPercentageOff ?? maxPercentageOff
    )

    const discountPriceRange =
      minDiscountPrice < minPrice
        ? {
            min: minDiscountPrice,
            max: maxDiscountPrice,
            maxPercentageOff,
            minPercentageOff,
          }
        : undefined

    priceRangesByColor[colorInfo.rawName] = {
      priceRange: {
        min: minPrice,
        max: maxPrice,
      },
      discountPriceRange: discountPriceRange,
    }
  }

  return priceRangesByColor
}

export function deriveColorsWithUnavailable<Color extends { rawName: string }>({
  availableProducts,
  colors,
  selectedFitKey,
  selectedSizeShorthand,
}: {
  availableProducts: AvailableProducts
  colors: readonly Color[]
  selectedFitKey: string | undefined
  selectedSizeShorthand?: string
}): Array<Color & { unavailable: boolean }> {
  return colors.map(color => {
    return {
      ...color,
      unavailable: !isColorAvailable({
        availableProducts,
        colorKey: color.rawName,
        selectedFitKey,
        selectedSizeShorthand,
      }),
    }
  })
}

type SizeShorthand = string

export interface AvailableProducts {
  [colorKey: string]:
    | {
        [fitKey: string]: SizeShorthand[] | undefined
      }
    | undefined
}

export function deriveAvailableProducts(
  products: readonly ProductAvailabilitySlice[]
): AvailableProducts {
  const availableProducts: AvailableProducts = {}

  for (const { availableSizes, colorInfo, rawFit } of products) {
    if (!colorInfo || !colorInfo.rawName) continue
    if (!rawFit) continue
    const availableFitsInColor = availableProducts[colorInfo.rawName] ?? {}
    availableFitsInColor[rawFit] = availableSizes
    availableProducts[colorInfo.rawName] = availableFitsInColor
  }

  return availableProducts
}

export function isColorFitComboValid({
  availableProducts,
  colorKey,
  fitKey,
}: {
  availableProducts: AvailableProducts
  colorKey: string
  fitKey: string | undefined
}): boolean {
  return !!(fitKey && availableProducts[colorKey]?.[fitKey])
}

export function getValidatedFit({
  availableProducts,
  colorKey,
  fitKey,
}: {
  availableProducts: AvailableProducts
  colorKey: string
  fitKey: string | undefined
}): string {
  if (!fitKey) return LegacyFit.Regular

  if (isColorFitComboValid({ availableProducts, colorKey, fitKey })) return fitKey

  // Fallbacks:
  if (isColorFitComboValid({ availableProducts, colorKey, fitKey: LegacyFit.Regular })) {
    return LegacyFit.Regular
  }
  if (isColorFitComboValid({ availableProducts, colorKey, fitKey: LegacyFit.Petite })) {
    return LegacyFit.Petite
  }
  if (isColorFitComboValid({ availableProducts, colorKey, fitKey: LegacyFit.Tall })) {
    return LegacyFit.Tall
  }
  return LegacyFit.Regular
}
