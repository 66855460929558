'use client'

import { createContainer } from 'unstated-next'

// IMPORTANT: This is a client component, we can only import types from a server file, not actual code/data.
import type { Translations as ServerTranslations } from '../_request/Translation.server'

export type Translations = ServerTranslations

const useTranslationImpl = (translations: Translations | undefined) => {
  return translations!
}

const TranslationContainer = createContainer(useTranslationImpl)
TranslationContainer.Provider.displayName = 'TranslationProviderImpl'

type TranslationProviderProps = {
  translations: Translations
  children?: React.ReactNode
}

export const TranslationProvider = (props: TranslationProviderProps) => {
  return (
    <TranslationContainer.Provider initialState={props.translations}>
      {props.children}
    </TranslationContainer.Provider>
  )
}
export const useTranslation = TranslationContainer.useContainer
