import {
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client'
import { useMemo } from 'react'

import { QueryStatus } from '../../lib/hooks/types'
import { GraphQlClients } from '../_config/GraphqlClient.config'
import { useGraphqlClients } from '../_providers/GraphqlClientsProvider.client'

const graphqlClientsMap: Record<GraphQlClients, keyof ReturnType<typeof useGraphqlClients>> = {
  'cache-optimized': 'cacheOptimizedClient',
  'auth-supported': 'authorizedClient',
  'shopify': 'shopifyClient',
}

export const useNextMutation = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  queryOptions?: Omit<MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'client'> & {
    client?: GraphQlClients
  }
) => {
  const additionalClients = useGraphqlClients()
  const [mutation, mutationResult] = useMutation(query, {
    ...queryOptions,
    client: queryOptions?.client
      ? additionalClients[graphqlClientsMap[queryOptions.client]]
      : undefined,
  })
  const status = useMemo<QueryStatus>(() => {
    if (!mutationResult.called) return 'idle'
    if (mutationResult.loading) return 'pending'
    if (mutationResult.error) return 'rejected'
    return 'resolved'
  }, [mutationResult.called, mutationResult.error, mutationResult.loading])
  const output = useMemo(() => {
    return [
      mutation,
      {
        ...mutationResult,
        status,
      },
    ] as const
  }, [mutation, mutationResult, status])
  return output
}
