import compact from 'lodash-es/compact'
import { useMemo } from 'react'
import { useObservable } from 'react-use'

import { QueryStatus } from '../../lib/hooks/types'
import { NostoPlacementId, useNostoClient } from '../_components/chrome/scripts/NostoScript'

export const useFetchNostoPlacementData = (
  nostoPlacementId: NostoPlacementId,
  options?: {
    skip: boolean
  }
) => {
  const skip = options?.skip ?? false
  const nostoClient = useNostoClient()
  const nostoRecommendationsByPlacementId = useObservable(nostoClient.recommendations$, {})
  const selectedNostoRecommendationCollection = nostoRecommendationsByPlacementId[nostoPlacementId]

  const nostoRecommendedProductHandles = useMemo<readonly string[] | undefined>(() => {
    if (!selectedNostoRecommendationCollection) return undefined

    /**
     * Nosto recommended product URLs are Shopify URLs and contain a product
     * handle that is consistent with Carica Product handles:
     */
    return compact(
      selectedNostoRecommendationCollection?.products.map(p => {
        const match = p.url.match(/products\/([\w\d-]+)/)
        return match && match[1]
      }) ?? []
    )
  }, [selectedNostoRecommendationCollection])

  const status = useMemo<Exclude<QueryStatus, 'idle'>>(() => {
    if (skip) return 'resolved'

    // We are waiting on Nosto JS
    if (!selectedNostoRecommendationCollection) return 'pending'

    return 'resolved'
  }, [selectedNostoRecommendationCollection, skip])

  return {
    data: {
      title: selectedNostoRecommendationCollection?.title,
      productHandles: nostoRecommendedProductHandles,
      resultId: selectedNostoRecommendationCollection?.result_id,
    },
    status,
  }
}
