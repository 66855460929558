import { getJwtAuthTokenStatus, jwtAuthTokenFromString } from '@syconium/iron-figs'

export type AccountAuthorization = {
  id: string
  email: string
  tokens: {
    auth: string
    refresh: string
    shopify: string
  }
}

export const convertCookiesToAccountAuthorization = ({
  authCookie,
  authRefreshCookie,
  shopifyCustomerCookie,
}: {
  authCookie: string | undefined
  authRefreshCookie: string | undefined
  shopifyCustomerCookie: string | undefined
}): AccountAuthorization | undefined => {
  if (!authCookie || !authRefreshCookie || !shopifyCustomerCookie) {
    return undefined
  }

  const authToken = authCookie ? jwtAuthTokenFromString(authCookie) : undefined
  if (!authToken) return undefined

  const { status: authStatus } = getJwtAuthTokenStatus(authCookie, authRefreshCookie)
  switch (authStatus) {
    case 'absent':
    case 'expired':
      return undefined
    case 'active':
    case 'expiring':
      return {
        id: authToken.id,
        email: authToken.email,
        tokens: {
          auth: authCookie,
          refresh: authRefreshCookie,
          shopify: shopifyCustomerCookie,
        },
      }
  }
}
