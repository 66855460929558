import { ApolloError } from '@apollo/client/errors'
import { GraphQLError } from 'graphql'

/**
 * Represents a determinate status for a given request or query.
 * Can be used to return a concrete "status" for any given asynchronous request.
 */
export type QueryStatus = 'idle' | 'resolved' | 'rejected' | 'pending'

export type QueryError = {
  message: string
  status: number
}

export const convertGraphqlErrorToQueryError = (
  graphqlError: ApolloError | GraphQLError
): QueryError => {
  let discoveredStatus: number | string | undefined = undefined
  if ((graphqlError as ApolloError)?.graphQLErrors) {
    // @ts-ignore extensions are custom, we don't have them typed at the moment.
    discoveredStatus = (graphqlError as ApolloError)?.graphQLErrors?.[0]?.extensions?.status
  } else if ((graphqlError as GraphQLError).extensions) {
    // @ts-ignore extensions are custom, we don't have them typed at the moment.
    discoveredStatus = graphqlError?.extensions?.response?.status
  }

  const status: number =
    typeof discoveredStatus === 'string'
      ? parseInt(discoveredStatus)
      : discoveredStatus
      ? discoveredStatus
      : 500

  return {
    message: graphqlError.message,
    status: isNaN(status) ? 500 : status,
  }
}
