'use client'

import Script from 'next/script'
import { useCallback } from 'react'

import { asyncGlobalVariable } from '../../../../lib/utils'
import { useConsentPolicy } from '../../../_providers/ConsentPolicyProvider.client'

const heap$ = asyncGlobalVariable(() => globalThis.heap)
const isHeapPresent = () => {
  return typeof globalThis.heap !== 'undefined'
}

export const useHeap = () => {
  const sendHeapEvent = useCallback(
    ({ eventName, props }: { eventName: string; props: Record<string, string> }) => {
      if (typeof window === 'undefined') return
      if (isHeapPresent()) {
        globalThis.heap.track(eventName, props)
      } else {
        heap$.subscribe(() => {
          if (isHeapPresent()) {
            globalThis.heap.track(eventName, props)
          }
        })
      }
    },
    []
  )

  return {
    sendHeapEvent,
  }
}

export const HeapScript = () => {
  const { performance } = useConsentPolicy()
  const heapId = process.env.NEXT_PUBLIC_HEAP_APP_ID

  if (!heapId || performance !== 'allowed') return null

  return (
    <Script
      id='heap-analytics'
      strategy='afterInteractive' // Analytics is set to afterInteractive
      dangerouslySetInnerHTML={{
        __html: `
        window.heap=window.heap||[],heap.load=function(e,t){if (window.FIGS_HEAP_LOAD_INITIATED) return; window.FIGS_HEAP_LOAD_INITIATED = true;window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load(${heapId});
      `,
      }}
    />
  )
}
