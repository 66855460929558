'use client'

import { ComponentProps } from 'react'
import styled from 'styled-components'

import { ResponsiveImage } from '@syconium/little-miss-figgy/dist/components/Image/ResponsiveImage'
import { Skeleton } from '@syconium/little-miss-figgy/dist/components/Skeleton'
import { TypeStyleHeadlineExtraExtraSmall } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'

import { Tile } from './Tiles.client'

type ProductTileAspectRatio = 'grid' | 'slider' | 'square' | number

const productTileAspectRatios: {
  md: Record<Exclude<ProductTileAspectRatio, number>, number>
  sm: Record<Exclude<ProductTileAspectRatio, number>, number>
} = {
  md: {
    square: 1,
    grid: 0.9014,
    slider: 0.7472,
  },
  sm: {
    square: 1,
    grid: 0.9014,
    slider: 0.7435,
  },
}

const ProductTile = Tile

const ProductTileTitle = styled(TypeStyleHeadlineExtraExtraSmall)`
  &:not(:first-child) {
    margin-top: 8px;

    ${fromMd} {
      margin-top: 16px;
    }
  }
`

const ProductTileTitleSkeleton = () => {
  return (
    <ProductTileTitle asTag='div'>
      <Skeleton height='19px' maxWidth='176px' />
    </ProductTileTitle>
  )
}

type ProductTileImageProps = Omit<ComponentProps<typeof ResponsiveImage>, 'aspectRatios'> & {
  aspectRatio: ProductTileAspectRatio
}

const ProductTileImage = ({ aspectRatio, ...responsiveImageProps }: ProductTileImageProps) => {
  const aspectRatioSm =
    typeof aspectRatio === 'number' ? aspectRatio : productTileAspectRatios.sm[aspectRatio]
  const aspectRatioMd =
    typeof aspectRatio === 'number' ? aspectRatio : productTileAspectRatios.md[aspectRatio]
  return (
    <ResponsiveImage
      {...responsiveImageProps}
      aspectRatios={{
        sm: aspectRatioSm,
        md: aspectRatioMd,
      }}
    />
  )
}

const ProductTileStyledImageWrap = styled.div<{
  $aspectRatioSm: number
  $aspectRatioMd: number
}>`
  ${untilMd} {
    padding-top: ${o => (1 / o.$aspectRatioSm) * 100}%;
  }
  ${fromMd} {
    padding-top: ${o => (1 / o.$aspectRatioMd) * 100}%;
  }
  position: relative;
`

const ProductTileStyledImageSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0;
`

type ProductTileImageSkeletonProps = {
  aspectRatio: ProductTileAspectRatio
}

const ProductTileImageSkeleton = ({ aspectRatio }: ProductTileImageSkeletonProps) => {
  const aspectRatioSm =
    typeof aspectRatio === 'number' ? aspectRatio : productTileAspectRatios.sm[aspectRatio]
  const aspectRatioMd =
    typeof aspectRatio === 'number' ? aspectRatio : productTileAspectRatios.md[aspectRatio]
  return (
    <ProductTileStyledImageWrap $aspectRatioSm={aspectRatioSm} $aspectRatioMd={aspectRatioMd}>
      <ProductTileStyledImageSkeleton animated />
    </ProductTileStyledImageWrap>
  )
}

type ProductTileColorsProps = {
  children: React.ReactNode
  className?: string
}

const ProductTileColors = styled((props: ProductTileColorsProps) => {
  return <TypeStyleHeadlineExtraExtraSmall {...props} asTag='div' color='tertiary' />
})`
  margin-top: 8px;

  ${fromMd} {
    margin-top: 16px;
  }
`

const ProductTileColorName = styled.span`
  margin-right: 12px;
`

const ProductTileColorCount = styled.span`
  font-weight: 500;
`

const ProductTileColorsSkeleton = () => {
  return (
    <ProductTileColors>
      <Skeleton height='19px' maxWidth='157px' />
    </ProductTileColors>
  )
}

const ProductTilePriceDisplayWrap = styled(TypeStyleHeadlineExtraExtraSmall)`
  &:not(:first-child) {
    margin-top: 8px;

    ${fromMd} {
      margin-top: 16px;
    }
  }
`

type ProductTilePriceProps = {
  children?: React.ReactNode
}

const ProductTilePrice = ({ children }: ProductTilePriceProps) => {
  return (
    <ProductTilePriceDisplayWrap asTag='div' color='primary'>
      {children}
    </ProductTilePriceDisplayWrap>
  )
}

const ProductTilePriceSkeleton = () => {
  return (
    <ProductTilePriceDisplayWrap>
      <Skeleton height='19px' maxWidth='107px' />
    </ProductTilePriceDisplayWrap>
  )
}

const ProductTileContentHorizontalLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  ${fromMd} {
    gap: 28px;
  }
`

export {
  ProductTile,
  ProductTileTitle,
  ProductTileTitleSkeleton,
  ProductTileImage,
  ProductTileImageSkeleton,
  ProductTileColors,
  ProductTileColorsSkeleton,
  ProductTilePrice,
  ProductTileColorName,
  ProductTileColorCount,
  ProductTilePriceSkeleton,
  ProductTileContentHorizontalLayout,
}
